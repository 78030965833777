import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import liveStream from './live.module.css';

import SiteMetadata from '../components/site-metadata/site-metadata';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import SponsorsFooter from '../components/sponsors-footer/sponsors-footer';

export const query = graphql`
  {
    prismicPageLiveStream {
      data {
        title {
          text
        }
        content {
          html
        }
        excerpt {
          text
        }
        main_image {
          thumbnails {
            hero_banner {
              url
            }
          }
        }
      }
    }
  }
`;

export default function Live({ data }) {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const page = data.prismicPageLiveStream;

        return (
          <div>
            <SiteMetadata
              title={page.data.title.text}
              image={page.data.main_image.thumbnails.hero_banner.url}
              description={page.data.excerpt.text}
            />

            <Header activePageName="live" />

            <img
              className={liveStream.image}
              src={page.data.main_image.thumbnails.hero_banner.url}
              alt={page.data.title.text}
            />

            <div className={liveStream.content}>
              <h1 className={liveStream.title}>{page.data.title.text}</h1>

              {/* rome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
              <div dangerouslySetInnerHTML={{ __html: page.data.content.html }} />
            </div>

            <SponsorsFooter />
            <Footer />
          </div>
        );
      }}
    />
  );
}
